import React from 'react';
import Layout from '../components/Layout';
import { withIntl } from '../i18n';
import WorkshopsPage from '../templates/Workshops';

const calendlyLink = (
  process.env.GATSBY_HOST.indexOf('dev') !== -1 ||
  process.env.GATSBY_HOST.indexOf('pre') !== -1
  ) ? 'https://calendly.com/middleware/workshop-pre' : 'https://calendly.com/middleware/workshop';

const workshops = {
  header: {
    title: 'workshopsTitle',
    subTitle: 'workshopsSubTitle',
    image: 'cap.png',
    // buttonsReverse: true,
    // buttonPay: {
    //   text: 'workshopsButton1',
    // },
    button1: {
      text: 'workshopsButton2',
      link: '/form/workshop#format=online',
      event: 'Header / Offline workshop'
    }
  },
  info: [
    {
      title: 'workshopsInfoDay',
      text: 'workshopsInfoDayText'
    },
    {
      title: 'workshopsInfoBusiness',
      text: 'workshopsInfoBusinessText'
    },
    {
      title: 'workshopsInfoProto',
      text: 'workshopsInfoProtoText'
    },
    {
      title: 'workshopsInfoParticipants',
      text: 'workshopsInfoParticipantsText'
    },
  ],
  program: {
    title: 'workshopsProgramText',
    image: 'books.png',
    list: [
      'workshopsProgramListNode',
      'workshopsProgramListService',
      'workshopsProgramListCases'
    ]
  },
  format: {
    title: 'workshopsFormatTitle',
    data: [
      {
        title: 'workshopsFormatOnlineTitle',
        text: 'workshopsFormatOnlineText',
        price: 'workshopsFormatOnlinePrice',
        button: {
          text: 'workshopsFormatOnlineButton',
          link: calendlyLink,
          event: 'Main / Online workshop',
        },
        image: 'manIt.png'
      },
      // {
      //   title: 'workshopsFormatOfflineTitle',
      //   text: 'workshopsFormatOfflineText',
      //   price: 'workshopsFormatOfflinePrice',
      //   comment: 'workshopsFormatOfflineComment',
      //   button: {
      //     text: 'workshopsFormatOfflineButton'
      //   },
      //   image: 'girl.png'
      // }
    ]
  },
  license: {
    title: 'workshopsLicenseTitle',
    data: [
      {
        title: 'workshopsLicenseCenterTitle',
        text: 'workshopsLicenseCenterText',
        button: {
          text: 'workshopsLicenseCenterButton',
          link: '/form/certification-partner',
          event: 'Corezoid Education Center'
        }
      },
      {
        title: 'workshopsLicenseInstructorTitle',
        text: 'workshopsLicenseInstructorText',
        button: {
          text: 'workshopsLicenseInstructorButton',
          link: '/form/certification-employees',
          event: 'Corezoid Instructor'
        }
      }
    ]
  }

};

const Workshops = (props) => (
  <Layout {...props}>
    <WorkshopsPage
      data={workshops}
    />
  </Layout>
);


export default withIntl(Workshops);

