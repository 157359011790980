import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {Button} from 'mw-style-react';
import './Workshops.scss';
import Utils from '../../utils/utils';
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import { Link } from '../../i18n';
import Image from '../../components/Image/Image';

class Workshops extends PureComponent {
  render() {
    const { intl, data } = this.props;

    return (
      <section className="workshops">
        <HeaderPage data={data.header}/>
        <div className="workshops__info__wrapper">
          <div className="workshops__info">
            {data.info.map((item, index) => (
              <div key={index} className="workshops__info__item">
                <div className="workshops__info__title">
                  {intl.formatMessage({id: item.title})}
                </div>
                <div className="workshops__info__text">
                  {intl.formatMessage({id: item.text})}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="grid-wrapper workshops__program">
          <div className="col-12 workshops__program__title">
             {intl.formatMessage({id: data.program.title})}
          </div>
          <div className="col-8 workshops__program__list">
            {data.program.list.map((item, index) => (
              <div
                key={index}
                className="workshops__program__item"
              >
                <div className="workshops__program__item__point">
                  <div className="workshops__program__item__day">
                    {intl.formatMessage({id: 'day'})}
                  </div>
                  <div className="workshops__program__item__count">
                    {index + 1}
                  </div>
                </div>
                <div className="workshops__program__item__text">
                  {intl.formatMessage({id: item})}
                </div>
              </div>
            ))}
          </div>
          <div className="col-4 workshops__program__image">
            <Image file={data.program.image}/>
          </div>
        </div>
        <div className="workshops__format__wrapper">
          <div className="workshops__format">
            <div className="workshops__format__title">
              {intl.formatMessage({id: data.format.title})}
            </div>
            <div className="workshops__format__list">
              {data.format.data.map((item, index) => (
                <div
                  key={index}
                  className="grid-wrapper workshops__format__item"
                >
                  <div className="col-9">
                    <div className="workshops__format__item__title">
                      {intl.formatMessage({id: item.title})}
                    </div>
                    <span
                      className="workshops__format__item__text"
                      dangerouslySetInnerHTML={{
                        __html: Utils.bbCodeToHtml(intl.formatMessage({id: item.text}))
                      }}
                    />
                    <div className="workshops__format__item__bottom">
                      <div className="workshops__format__item__price">
                        <div className="workshops__format__item__price__text">
                          {intl.formatMessage({id: item.price})}
                        </div>
                        {item.comment &&
                          <div className="workshops__format__item__price__comment">
                              {intl.formatMessage({id: item.comment})}
                          </div>
                        }
                      </div>
                      <Link
                        to={item.button.link}
                        event={item.button.event}
                      >
                        <Button
                          className={'button__default workshops__format__item__price__btn'}
                          label={intl.formatMessage({id: item.button.text})}
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="col-3 workshops__program__image">
                    <Image file={item.image}/>
                  </div>
                  {data.format.data.length !== index + 1 &&
                    <div className="col-12 workshops__format__item__separator" />
                  }
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="workshops__license">
          <div className="workshops__license__title">
            {intl.formatMessage({id: data.license.title})}
          </div>
          <div className="grid-wrapper workshops__license__list">
          {
            data.license.data.map((item, index) => (
              <div key={index} className="col-6 workshops__license__item">
                <div className="workshops__license__item__title">
                  {intl.formatMessage({id: item.title})}
                </div>
                <div className="workshops__license__item__text">
                  {intl.formatMessage({id: item.text})}
                </div>
                <Link
                  to={item.button.link}
                  event={item.button.event}
                >
                  <Button
                    className={'button__default workshops__license__item__btn'}
                    label={intl.formatMessage({id: item.button.text})}
                  />
                </Link>
              </div>
            ))
          }
          </div>
        </div>
      </section>
    );
  }
}


Workshops.propTypes = {
  intl: PropTypes.object,
  data: PropTypes.object,
};

export default injectIntl(Workshops);

